import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

export default function Login() {

    const handleSubmit = async (e) => {
        e.preventDefault();
        const credentials = Object.fromEntries((new FormData(e.target)).entries());

        try {
            const response = await api.post(process.env.REACT_APP_API_URL + 'auth/login', credentials);

            localStorage.setItem('access_token', response.data.access_token);
            window.setIsLogged(true);
        } catch (error) {
            // Handle login error
        }
    };

    return (
        <div className="login-container card p-5">
            <h2 className="text-center mb-3">Авторизация</h2>

            <form className="" onSubmit={handleSubmit}>
                <div className="form-floating mb-3">
                    <input id="email" type="email" name="email" className="form-control" placeholder="name@example.com"/>
                    <label htmlFor="email" className="form-label">Email</label>
                </div>
                <div className="form-floating mb-3">
                    <input id="password" type="password" name="password" className="form-control" placeholder="example"/>
                    <label htmlFor="password" className="form-label">Пароль</label>
                </div>
                <button type="submit" className="btn btn-primary float-end">
                    Войти
                </button>
            </form>
        </div>
    );
}