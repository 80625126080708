import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {useEffect, useState} from "react";
import api from "../../api/api";
import ClientForm from "./ClientForm";

export default function Admin() {
    const fetchClients = async () => {
        try {
            const response = await api.get('/company');
            setClients(response.data);
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchClient = async (id, cb) => {
        try {
            const response = await api.get('/company/' + id);
            cb(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const createClient = async(data) => {
        try {
            const response = await api.post('/company', {
                name: data.name,
                amo_id: data.amo_id
            });
            console.log(response.data);
            fetchClients();
            setModalShow(false);
        } catch (error) {
            if (error.response.status === 422) {
                setErrors(error.response.data.message);
            } else {
                console.log(error);
            }
        }
    };

    const updateClient = async(data) => {
        try {
            const response = await api.patch('/company/' + currentItemId, {
                name: data.name,
                amo_id: data.amo_id
            });
            console.log(response.data);
            fetchClients();
            setModalShow(false);
        } catch (error) {
            if (error.response.status === 422) {
                setErrors(error.response.data.message);
            } else {
                console.log(error);
            }
        }
    };

    const deleteClient = async(id) => {
        try {
            const response = await api.delete('/company/' + id);
            console.log(response.data);
            fetchClients();
        } catch (error) {
            console.log(error);
        }
    };

    const [clients, setClients] = useState([]);
    useEffect(() => {
        fetchClients();
    }, []);

    const [modalShow, setModalShow] = useState(false);
    const [modalMode, setModalMode] = useState('create');
    const [currentItemId, setCurrentItemId] = useState(null);
    const [currentClient, setCurrentClient] = useState({name: '', amo_id: ''});
    const [errors, setErrors] = useState({});

    const handleClose = () => {
        setModalShow(false);
        setErrors({});
    }
    const handleCreateDialog = () => {
        setModalShow(true);
        setModalMode('create');
    }
    const handleDeleteDialog = (id) => {
        if (window.confirm('Вы действительно хотите удалить клиента ID '
            + id
            + '? Это действие приведёт к удалению всех пользователей клиента')) {
            deleteClient(id);
        }
    }
    const handleUpdateDialog = (id) => {
        setCurrentClient({name: '', amo_id: ''});
        fetchClient(id, (client) => {
            setCurrentClient(client);
            setCurrentItemId(id);
            setModalShow(true);
            setModalMode('update');
        });
    }

    const onSubmit = (formData) => {
        setErrors({});
        if (modalMode === 'create') {
            createClient(formData);
        } else {
            updateClient(formData);
        }
    };

    return (
        <div>
            <div>
                <ClientForm
                    errors={errors}
                    modalShow={modalShow}
                    modalMode={modalMode}
                    handleClose={handleClose}
                    client={currentClient}
                    onSubmit={onSubmit}
                />
            </div>

            <div>
                <h3>Управление клиентами</h3>
                <button className="btn btn-primary" onClick={handleCreateDialog}>Создать</button>
                <Table stripped>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Название</th>
                            <th>Amo ID</th>
                            <th>Пользователи</th>
                            <th>Действия</th>
                        </tr>
                    </thead>

                    <tbody>
                    {clients.map((client) => {
                        return (
                        <tr>
                            <td>{client.id}</td>
                            <td>{client.name}</td>
                            <td>{client.amo_id}</td>
                            <td>
                                <a className="btn btn-primary" href={'/admin/client/' + client.id}>Управлять</a>
                            </td>
                            <td>
                                <Dropdown
                                    as={ButtonGroup}
                                >
                                    <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                                        Действия
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => handleUpdateDialog(client.id)}>Изменить</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDeleteDialog(client.id)}>Удалить</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                        </tr>
                        )
                    })}
                    </tbody>
                </Table>
            </div>

        </div>
    );
}