import './App.css';
import {Navigate, Route} from "react-router-dom";
import {Routes} from "react-router";
import Login from "./components/login/Login";
import Admin from "./components/admin/Admin";
import Billing from "./components/billing/Billing";
import Sidebar from "./components/Sidebar";
import Client from "./components/admin/Client";
import {useEffect, useState} from "react";
import api from "./api/api";
import NoAccess from "./components/login/NoAccess";
import Logout from "./components/login/Logout";

function App() {
    const [isLogged, setIsLogged] = useState(localStorage.getItem('access_token') !== null);
    window.setIsLogged = setIsLogged;

    const [user, setUser] = useState({});

    useEffect(() => {
        const fetchUser = async() => {
            const response = await api.get('/user/profile');
            setUser(response.data);
        }

        isLogged && fetchUser();
    }, [isLogged]);


    return (
        <div className={'App container-fluid ' + (!isLogged ? 'login-page' : '')}>
            {isLogged ? (
            <div className="row">
                <Sidebar user={user} />

                <div className="container p-3 col-xl-10 col-md-9">
                    <Routes>
                        {user.admin_access + user.billing_access === 0 ?
                            (<>
                            <Route path="/noaccess" element={<NoAccess />} />
                            <Route path="*" element={<Navigate replace to="/noaccess" />} />
                            <Route path="/logout" element={<Logout />} />
                            </>)
                            :
                            (
                                <>
                                    <Route path="/" element={<Navigate replace to="/billing" />} />
                                    <Route path="/login" element={<Navigate replace to="/billing" />} />
                                    <Route path="/logout" element={<Logout />} />
                                    {user.admin_access > 0 && <Route path="/admin" element={<Admin />}/>}
                                    {user.admin_access > 0 && <Route path="/admin/client/:clientId" element={<Client />}/>}
                                    {user.billing_access > 0 && <Route path="/billing" element={<Billing />}/>}
                                </>
                            )
                        }
                    </Routes>
                </div>
            </div>
            ) : (
                <Routes>
                    <Route path="*" element={<Navigate replace to="/login" />} />
                    <Route path='/login' element={<Login />} />
                </Routes>
            )}
        </div>
    );
}

export default App;
