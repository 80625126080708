import {useEffect} from "react";

export default function Logout() {
    useEffect(() => {
        window.setIsLogged(false);
        localStorage.removeItem('access_token');
        window.location.reload();
    }, []);

    return (<div>Выход</div>);
}