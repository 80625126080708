import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {useEffect, useState} from "react";
import api from "../../api/api";
import UserForm from "./UserForm";
import {AccessLevels} from "../../constants";
import {useParams} from "react-router";

export default function Client() {
    let { clientId } = useParams();

    const fetchUsers = async () => {
        try {
            const response = await api.get('/company/' + clientId + '/users');
            setUsers(response.data);
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchUser = async (id, cb) => {
        try {
            const response = await api.get('/user/' + id);
            cb(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const createUser = async(data) => {
        try {
            const response = await api.post('/user', {...data, company_id: clientId});
            console.log(response.data);
            fetchUsers();
            setModalShow(false);
        } catch (error) {
            if (error.response.status === 422) {
                setErrors(error.response.data.message);
            } else {
                console.log(error);
            }
        }
    };

    const updateUser = async(data) => {
        try {
            const response = await api.patch('/user/' + currentItemId, data);
            console.log(response.data);
            fetchUsers();
            setModalShow(false);
        } catch (error) {
            if (error.response.status === 422) {
                setErrors(error.response.data.message);
            } else {
                console.log(error);
            }
        }
    };

    const deleteUser = async(id) => {
        try {
            const response = await api.delete('/user/' + id);
            console.log(response.data);
            fetchUsers();
            setModalShow(false);
        } catch (error) {
            console.log(error);
        }
    };

    const [users, setUsers] = useState([]);
    useEffect(() => {
        fetchUsers();
    }, []);

    const [modalShow, setModalShow] = useState(false);
    const [modalMode, setModalMode] = useState('create');
    const [currentItemId, setCurrentItemId] = useState(null);
    const [currentUser, setCurrentUser] = useState({});
    const [errors, setErrors] = useState({});

    const handleClose = () => {
        setModalShow(false);
        setErrors({});
    }
    const handleCreateDialog = () => {
        setCurrentUser({});
        setModalShow(true);
        setModalMode('create');
    }
    const handleDeleteDialog = (id) => {
        if (window.confirm('Вы действительно хотите удалить пользователя ID ' + id)) {
            deleteUser(id);
        }
    }
    const handleUpdateDialog = (id) => {
        setCurrentUser({});
        fetchUser(id, (user) => {
            setCurrentUser(user);
            setCurrentItemId(id);
            setModalShow(true);
            setModalMode('update');
        });
    }

    const onSubmit = (formData) => {
        setErrors({});
        if (modalMode === 'create') {
            createUser(formData);
        } else {
            updateUser(formData);
        }
    };

    return (
        <div>
            <div>
                <UserForm
                    errors={errors}
                    modalShow={modalShow}
                    modalMode={modalMode}
                    handleClose={handleClose}
                    user={currentUser}
                    onSubmit={onSubmit}
                />
            </div>

            <div>
                <h3>Пользователи клиента</h3>
                <button className="btn btn-primary" onClick={handleCreateDialog}>Создать</button>
                <Table stripped>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Имя</th>
                        <th>Email</th>
                        <th>Права</th>
                        <th>Действия</th>
                    </tr>
                    </thead>

                    <tbody>
                    {users.map((user) => {
                        return (
                            <tr>
                                <td>{user.id}</td>
                                <td>{user.first_name + ' ' + user.last_name}</td>
                                <td>{user.email}</td>
                                <td>
                                    Биллинг: {AccessLevels[user.billing_access]}
                                </td>
                                <td>
                                    <Dropdown
                                        as={ButtonGroup}
                                    >
                                        <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                                            Действия
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleUpdateDialog(user.id)}>Изменить</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleDeleteDialog(user.id)}>Удалить</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
            </div>

        </div>
    );
}