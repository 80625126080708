import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Input from "../helpers/Input";

export default function UserForm({errors, modalShow, modalMode, handleClose, user, onSubmit}) {
    function handleSubmit(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const formJson = Object.fromEntries(formData.entries());
        onSubmit(formJson);
    }

    return (
        <Modal show={modalShow} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{modalMode === 'create' ? 'Создание клиента' : 'Редактирование клиента'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Input
                        errors={errors}
                        name="email"
                        defaultValue={modalMode === 'update' && user ? user.email : ''}
                        label="Email"
                    />

                    <Input
                        errors={errors}
                        name="first_name"
                        defaultValue={modalMode === 'update' && user ? user.first_name : ''}
                        label="Имя"
                        />

                    <Input
                        errors={errors}
                        name="last_name"
                        defaultValue={modalMode === 'update' && user ? user.last_name : ''}
                        label="Фамилия"
                    />

                    <Input
                        type="password"
                        errors={errors}
                        name="password"
                        label={modalMode === 'update' ? 'Новый пароль' : 'Пароль'}
                    />

                    <div className="mb-3">
                        <h5>Настройки доступа</h5>
                        <div className="access-level-block">
                            <div className="access-level-label align-items-center">
                                Биллинг
                            </div>

                            <div className="access-level-select">
                                <select name="billing_access" className="form-select">
                                    <option value="0" selected={user.billing_access === 0}>
                                        Нет
                                    </option>
                                    <option value="1" selected={user.billing_access === 1}>
                                        Просмотр
                                    </option>
                                    <option value="2" selected={modalMode === 'create'|| user.billing_access === 2}>
                                        Пользователь
                                    </option>
                                    <option value="3" selected={user.billing_access === 3}>
                                        Полный
                                    </option>
                                </select>
                            </div>
                        </div>


                    </div>


                    <Button type="submit" variant="primary" className="float-end">
                        {modalMode === 'create' ? 'Создать' : 'Сохранить'}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}