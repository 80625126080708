import Table from "react-bootstrap/Table";
import api from "../../api/api";
import {useEffect, useState} from "react";

const currentDate = new Date();
currentDate.setUTCHours(0, 0, 0);
currentDate.setUTCDate(1);
const monthStartDate = new Date(currentDate.getTime() + currentDate.getTimezoneOffset() * 60000);
currentDate.setUTCMonth(currentDate.getUTCMonth() + 1);
currentDate.setUTCDate(0);
const monthEndDate = new Date(currentDate.getTime() + currentDate.getTimezoneOffset() * 60000);

const formattedStartDate = (new Date(monthStartDate.getTime() - monthStartDate.getTimezoneOffset()*60*1000)).toISOString().split('T')[0];
const formattedEndDate = (new Date(monthEndDate.getTime() - monthEndDate.getTimezoneOffset()*60*1000)).toISOString().split('T')[0]
console.log(monthStartDate, monthEndDate, formattedStartDate, formattedEndDate);

export default function Billing() {
    const [startDate, setStartDate] = useState(formattedStartDate);
    const [endDate, setEndDate] = useState(formattedEndDate);
    const [entities, setEntities] = useState([]);

    useEffect(() => {
        const fetchEntities = async () => {
            try {
                const response = await api.get('/billing/payments', {
                    params: {
                        start: startDate,
                        end: endDate
                    }
                });
                setEntities(response.data.entities);
                console.log(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchEntities();
    }, [startDate, endDate]);

    const [stats, setStats] = useState({});
    useEffect(() => {
        const fetchStats = async () => {
            try {
                const response = await api.get('/billing/stats');
                setStats(response.data);
                console.log(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchStats();
    }, []);


    const moneyFormat = (number) => {
        return Math.floor(number).toLocaleString('ru');
    };

    const amount = (entity) => {
        let currency = 'руб.';
        if (entity.bonuses) {
            currency = 'RAD';
        }

        if (entity.type === 'charge') {
            return <span className="payment-charge"><span>-</span> {moneyFormat(entity.amount)} {currency}</span>
        }
        return <span className="payment-deposit"><span>+</span> {moneyFormat(entity.amount)} {currency}</span>;
    };

    return (
        <div>
            <div className="row mb-3">
                <div className="col-4">
                    <div className="billing-card card p-3 text-center">
                        <h5>Баланс, рублей</h5>
                        <h1>{moneyFormat(stats.balance)}</h1>
                    </div>
                </div>

                <div className="col-4">
                    <div className="billing-card card p-3 text-center">
                        <h5>Бонусы RAD</h5>
                        <h1>{moneyFormat(stats.bonuses)}</h1>
                    </div>
                </div>

                <div className="col-4">
                    <div className="billing-card card p-3 text-center">
                        <h5>Прогноз оплат на текущий месяц</h5>
                        <h1>{moneyFormat(stats.planned_payments_total)}</h1>
                    </div>
                </div>
            </div>

            <div>
                <div className="row mb-3">
                    <div className="col-3">
                        <input
                            onChange={(e) => setStartDate(e.target.value)}
                            className="form-control" type="date" defaultValue={formattedStartDate} />
                    </div>

                    <div className="col-3">
                        <input
                            onChange={(e) => setEndDate(e.target.value)}
                            className="form-control" type="date" defaultValue={formattedEndDate} />
                    </div>
                </div>


                <Table className="table">
                    <tbody>
                    {entities.map((entity) => {
                        return (
                            <tr>
                                <td>{amount(entity)}</td>
                                <td>{entity.reason}</td>
                                <td>{(new Date(entity.created_at)).toLocaleDateString("ru-RU")}</td>
                            </tr>)
                        })}
                    </tbody>
                </Table>
            </div>
        </div>


    );
}