import {NavLink} from "react-router-dom";

export default function Sidebar({user}) {

    const btnClass = "btn btn-secondary ";
    return (
        <div className="sidebar container p-3 col-xl-2 col-md-3">
            <h1 className="text-center">Radex</h1>
            <div className="card mb-3">
                <div className="card-header">
                    {user.company_name}
                </div>

                <div className="card-body">
                    <h5 className="card-title">{user.first_name} {user.last_name}</h5>
                    <div><a className="card-link" href="/logout">Выйти</a></div>
                </div>

            </div>

            <nav>
                <ul className="nav nav-pills flex-column mb-auto">
                    {user.admin_access > 0 && <li>
                        <NavLink className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')} to="/admin">Администрирование</NavLink>
                    </li>}
                    {user.billing_access > 0 && <li className="nav-item">
                        <NavLink className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')} to="/billing">Биллинг</NavLink>
                    </li>}
                </ul>
            </nav>
        </div>
    );
}