import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Input from "../helpers/Input";

export default function ClientForm({errors, modalShow, modalMode, handleClose, client, onSubmit}) {
    function handleSubmit(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const formJson = Object.fromEntries(formData.entries());
        onSubmit(formJson);
    }

    return (
        <Modal show={modalShow} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{modalMode === 'create' ? 'Создание клиента' : 'Редактирование клиента'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Input
                        errors={errors}
                        name="name"
                        defaultValue={modalMode === 'update' && client ? client.name : ''}
                        label="Название"
                        />

                    <Input
                        errors={errors}
                        name="amo_id"
                        defaultValue={modalMode === 'update' && client ? client.amo_id : ''}
                        label="Амо ID"
                    />
                    <Button type="submit" variant="primary" className="float-end">
                        {modalMode === 'create' ? 'Создать' : 'Сохранить'}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}