import Form from "react-bootstrap/Form";

export default function Input({errors, name, label, defaultValue, type}) {
    return (
        <Form.Group className="mb-3">
            <Form.Label>{label}</Form.Label>
            <input name={name} type={type ? type : 'text'} className={'form-control ' + (errors.hasOwnProperty(name) ? 'is-invalid' : '')}
                   defaultValue={defaultValue} />

            <div className="invalid-feedback">
                {errors.hasOwnProperty(name) && errors[name].map(message => message)}
            </div>
        </Form.Group>
    );
}